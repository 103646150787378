import React, { Component } from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';

const Holder = styled.div`
  margin: 6rem 0;
`;

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const validationSchema = Yup.object().shape( {
  name: Yup.string()
  .min( 2, 'Too Short!' )
  .max( 50, 'Too Long!' )
  .required( '* Name is Required.' ),
  company: Yup.string(),
  email: Yup.string()
  .email( '* Enter a Valid Email.' )
  .required( '* Email is Required.' ),
} );

const encode = ( data ) => {
  return Object.keys( data )
  .map( key => encodeURIComponent( key ) + '=' + encodeURIComponent( data[ key ] ) )
  .join( '&' )
};

class NewsletterForm extends Component {

  constructor( props ) {
    super( props );
    this.state = {
      success: false,
    }
  }

  render() {
    return (
      <Holder>
        <h3>Stay updated</h3>
        <p>Sign up to receive the latest digital forensic news, invitations to our special events and training opportunities.</p>
        <Formik
          initialValues={{ name: '', email: '', company: '' }}
          validationSchema={validationSchema}
          onSubmit={( values, { setSubmitting } ) => {
            fetch( '/', {
              method: 'POST',
              headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
              body: encode( {
                'form-name': 'subscribe',
                ...values,
              } ),
            } )
            .then( () => {
              alert( 'Success!' );
              setSubmitting( false )
            } )
            .catch( error => {
              alert( 'Error: Please Try Again!' );
              setSubmitting( false )
            } )
          }}
          render={( {
                      isSubmitting,
                      handleSubmit,
                      handleReset,
                    } ) => (
            <form className='form'
              name='subscribe'
              onSubmit={handleSubmit}
              onReset={handleReset}
              data-netlify='true'
              data-netlify-honeypot='bot-field'
            >
              <div className='field'>
                <label htmlFor='name' className='label'>Name</label>
                <Field
                  className='input'
                  type='text'
                  name='name'
                />
                <ErrorMessage className="errorMsg" name="name" component="small"/>
              </div>
              <div className='field'>
                <label htmlFor='email' className='label'>Email</label>
                <Field
                  className='input'
                  type='text'
                  name='email'
                />
                <ErrorMessage className="errorMsg" name="email" component="small"/>
              </div>
              <div className='field'>
                <label htmlFor='name' className='label'>Company Name</label>
                <Field
                  className='input'
                  type='text'
                  name='company'
                />
                <ErrorMessage className="errorMsg" name="company" component="small"/>
              </div>
              <Buttons>
                <input name='submit' type='submit' disabled={isSubmitting} value='Subscribe'
                  className='button'/>
              </Buttons>
            </form>
          )}
        />
      </Holder>
    )
  }
}

export default NewsletterForm;
