import React, { Component } from 'react';
import styled from 'styled-components';
import Container from '../atoms/Container';

const Holder = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 8rem 0;
  @media(${props => props.theme.breakpoints.md}) {
    grid-template-columns: 1fr 2fr 1fr;
  }
`;

const Content = styled.div`
  @media(${props => props.theme.breakpoints.md}) {
    grid-column: 2/3;
  }
  > :first-child { margin-top: 0; }
  > :last-child { margin-bottom: 0; }
`;

class CenteredPage extends Component {
  render() {
    return (
      <Container>
        <Holder>
          <Content>{this.props.children}</Content>
        </Holder>
      </Container>
    )
  }
}

export default CenteredPage;
