import React, { Component } from 'react';
import { graphql } from 'gatsby';
import RichTextConverter from '../components/atoms/RichTextConverter';
import SEO from '../components/molecules/SEO';
import PreHead from '../components/molecules/PreHead';
import CenteredPage from '../components/organisms/CenteredPage';
import NewsletterForm from '../components/molecules/NewsletterForm';

class Page extends Component {
  render() {

    const { contentfulPage } = this.props.data;
    const { title, content, description, heroImage } = contentfulPage;

    return (
      <>
        <SEO title={title} description={description}/>
        {heroImage && <PreHead text={title} fluidImage={heroImage.fluid}/>}
        <CenteredPage>
          {!heroImage && <h1>{title}</h1>}
          {content && <section><RichTextConverter content={content.json}/></section>}
          <NewsletterForm/>
        </CenteredPage>
      </>
    )
  }
}

export default Page;

export const pageQuery = graphql`
    query($id: String!) {
        contentfulPage(id: { eq: $id }) {
            title
            content {
                json
            }
            description
            heroImage {
                fluid(maxWidth: 3000) {
                    src
                    srcSet
                    sizes
                    aspectRatio
                }
            }
        }
    }
`;
